import _export from "../internals/export";
import _numberParseInt from "../internals/number-parse-int";
var $ = _export;
var $parseInt = _numberParseInt; // `parseInt` method
// https://tc39.es/ecma262/#sec-parseint-string-radix

$({
  global: true,
  forced: parseInt != $parseInt
}, {
  parseInt: $parseInt
});
export default {};